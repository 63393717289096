<template>
    <el-dialog
            title="模型测试"
            :visible.sync="dialogVisible"
            width="800px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="handleClose">
        <div v-show="pageState == 0">
            <div class="flex_b_e">
                <div v-if="imgList.length" style="position: relative;width: 400px;">
                    <div class="el-icon-error" @click="deleteFile(imgList[0],0)"
                         style="position:absolute; top: -8px;right: -8px;font-size: 16px;z-index: 99;cursor: pointer"></div>
                    <el-image
                            style="width: 400px;"
                            :src="imgList[0].netUrl"
                            :fit="'contain'"
                            :preview-src-list="[imgList[0].netUrl]"
                    >
                    </el-image>
                </div>
                <div v-else style="width: 400px;height: 300px;background: rgb(242,242,242)"></div>
                <resume-upload ref="resume-upload" :uploadNum="1" :upFileSize="10" :allowFiles="imgPattern" style="margin-left: 10px"
                               :filtButton="true"
                               :filtButtonName="'上传图片'"
                               @upFileDate="(data)=>getUpData(data)"></resume-upload>
            </div>
            <div v-show="imgList.length" class="text_center">
                <el-button size="small" type="primary" @click="sureData()" v-no-more-click>开始测试</el-button>
            </div>
        </div>
        <div v-show="pageState == 1">
            <div class="audbfuyh"
                 v-loading="loading"
                 element-loading-text="测试中，请勿关闭页面！"
                 element-loading-spinner="el-icon-loading">
                <el-result icon="success" title="" :subTitle="`已完成模型测试！用时${dateCont}`"></el-result>
                <div class="text_center">
<!--                    <div style="margin-bottom: 5px">测试结果：命中</div>-->
                    <el-image
                            style="width: 300px;"
                            :src="resultUrl"
                            :fit="'contain'"
                            :preview-src-list="[resultUrl]"
                    >
                    </el-image>
                </div>
                <div class="text_center" style="margin-top: 10px">
                    <el-button size="small" type="primary" @click="testAgain()">再测一次</el-button>
                    <el-button size="small" type="primary" @click="handleClose()">完成</el-button>
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import resumeUpload from "@/components/Upload/resumeUpload.vue";

export default {
    name: "modelTest",
    components: {resumeUpload},
    data() {
        return {
            dialogVisible: false,
            selectData: {},
            pageState: 0,

            imgPattern: ['PNG', 'BMP', 'JPG', 'JPEG'],
            imgList: [],
            loading: false,
            dateCont: '',
            resultUrl: '',
        }
    },

    methods: {
        init(row) {
            this.pageState = 0
            this.selectData = row
            this.dialogVisible = true
            this.$nextTick(()=>{
                this.testAgain()
            })
        },

        getUpData(data) {
            this.imgList = data.map((item, index) => {
                if (item.upDate) {
                    this.$set(item.upDate, 'fileID', item.id)
                    this.$set(item.upDate, 'fileSort', index + 1)
                    this.$set(item.upDate, 'masterView', 1)
                    this.$set(item.upDate, 'fileSize', item.origSize)
                    return item.upDate
                } else {
                    return item
                }
            })
            console.log(this.imgList)
        },

        deleteFile(row, index) {
            this.$confirm('此操作将删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.imgList.splice(index, 1)
                this.$refs["resume-upload"].deleteFile(row.fileID ? row.fileID : row.id)
            }).catch(() => {
            })
        },

        sureData() {
            this.loading = true
            this.pageState++;
            this.$axios(this.api.original.recognition, {
                netUrl: this.imgList[0].netUrl,
            }, 'get').then((res) => {
                if (res.status) {
                    this.loading = false
                    this.dateCont = res.data.formattedDuration
                    this.resultUrl = res.data.result
                } else {
                    this.$message.error(res.msg);
                }
            })
        },

        testAgain() {
            this.pageState = 0
            this.imgList.forEach(item => {
                this.$refs["resume-upload"].deleteFile(item.fileID, null, '1')
            })
            this.imgList = []
        },

        handleClose() {
            if (this.loading) {
                this.$confirm(`正在测试数据模型，是否中断操作？`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    // this.testAgain()
                    this.dialogVisible = false
                })
            } else {
                // this.testAgain()
                this.dialogVisible = false
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.audbfuyh ::v-deep .el-loading-mask {
  background-color: rgba(255, 255, 255, 1) !important;

  .el-loading-spinner {
    top: 50% !important;
    transform: translateY(-50%) !important;

    .el-icon-loading {
      font-size: 150px;
      color: #b4bbc5;
    }

    .el-loading-text {
      color: #0E1525;
    }
  }
}
</style>